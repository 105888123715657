import { IonButton, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, useIonToast } from '@ionic/react';
import { useCallback } from 'react';
import { Filesystem, Directory, Encoding } from '@capacitor/filesystem';

import { Storage } from '@ionic/storage';

import styles from './SettingsTab.module.css';
import { GardenPatch } from '../models/Patches';

import { auth, firebase, firestore } from '../firebase';
import { useAuthState } from 'react-firebase-hooks/auth';
import { time } from 'ionicons/icons';
import { Capacitor } from '@capacitor/core';

import User from 'firebase/app'

const SettingsTab: React.FC = () => {
  const [presentToast, dismissToast] = useIonToast();

  const [user] = useAuthState(auth);

  const store = new Storage();
  store.create();

  const importGarden = useCallback(() => {
    Filesystem.readFile({
      path: 'garden-patch/garden.json',
      directory: Directory.Documents,
      encoding: Encoding.UTF8,
    }).then((contents) => {
      store.set('GardenPatches', contents.data);

      presentToast({
        message: "Imported your gardens from a file!",
        duration: 1000,
        position: "top",
        color: "tertiary"
      });
    });
  }, [presentToast]);

  const signOut = () => {
    auth.signOut().then(() => {
      presentToast({
        message: `Signed out successfully.`,
        duration: 2000,
        position: "top",
        color: "primary",
      });
    });
  };

  const exportGarden = useCallback(() => {
    let fileName = 'garden-patch/garden.json';

    store.get('GardenPatches').then((value: string) => {
      Filesystem.writeFile({
        path: fileName,
        data: value,
        directory: Directory.Documents,
        encoding: Encoding.UTF8,
      }).then(() => {
          presentToast({
          message: "Exported your gardens to a file!",
          duration: 1000,
          position: "top",
          color: "tertiary"
        });
      });
    });
  }, [presentToast]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Settings</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Settings</IonTitle>
          </IonToolbar>
        </IonHeader>
        <div className={styles.pageBody}>
          <div className={styles.buttons}>
            {user && <IonButton color="danger" onClick={() => signOut()}>Sign out</IonButton> }
            {/* <IonButton onClick={() => importGarden()}>Import garden</IonButton>
            <IonButton onClick={() => exportGarden()}>Export garden</IonButton> */}
          </div>

        </div>
      </IonContent>
    </IonPage>
  );
};

export default SettingsTab;
