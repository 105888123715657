import { PlantInfo } from "../models/Patches";

export const plantData: PlantInfo[] = [{
		ID: 1,
		name: "La Noscean Orange",
		type: "Fruit",
		growTime: 120,
		wiltTime: 48,
		harvestLocation: "Lower La Noscea (32, 16)",
		nodeLevel: "10",
		iLvl: 7
	},
	{
		ID: 2,
		name: "Lowland Grape",
		type: "Fruit",
		growTime: 120,
		wiltTime: 48,
		harvestLocation: "Lower La Noscea (26, 22)",
		nodeLevel: "15",
		iLvl: 11
	},
	{
		ID: 3,
		name: "Paprika",
		type: "Vegetable",
		growTime: 120,
		wiltTime: 48,
		harvestLocation: "Western La Noscea (32, 29)",
		nodeLevel: "20",
		iLvl: 11
	},
	{
		ID: 4,
		name: "Lavender",
		type: "Herb & Nut",
		growTime: 120,
		wiltTime: 48,
		harvestLocation: "Central Shroud (21, 23)",
		nodeLevel: "15",
		iLvl: 12
	},
	{
		ID: 5,
		name: "La Noscean Lettuce",
		type: "Vegetable",
		growTime: 120,
		wiltTime: 48,
		harvestLocation: "Middle La Noscea (22, 19)",
		nodeLevel: "15",
		iLvl: 13
	},
	{
		ID: 6,
		name: "Coerthan Carrot",
		type: "Vegetable",
		growTime: 120,
		wiltTime: 48,
		harvestLocation: "Western Thanalan (23, 20)",
		nodeLevel: "15",
		iLvl: 13
	},
	{
		ID: 7,
		name: "Olive",
		type: "Vegetable",
		growTime: 120,
		wiltTime: 48,
		harvestLocation: "Lower La Noscea (26, 22)",
		nodeLevel: "15",
		iLvl: 13
	},
	{
		ID: 8,
		name: "Black Pepper",
		type: "Herb & Nut",
		growTime: 120,
		wiltTime: 48,
		harvestLocation: "Central Thanalan (25, 20)",
		nodeLevel: "15",
		iLvl: 14
	},
	{
		ID: 9,
		name: "Faerie Apple",
		type: "Fruit",
		growTime: 120,
		wiltTime: 48,
		harvestLocation: "East Shroud (15, 27)",
		nodeLevel: "20",
		iLvl: 16
	},
	{
		ID: 10,
		name: "Sun Lemon",
		type: "Fruit",
		growTime: 120,
		wiltTime: 48,
		harvestLocation: "Lower La Noscea (35, 16)",
		nodeLevel: "20",
		iLvl: 17
	},
	{
		ID: 11,
		name: "Gysahl Greens",
		type: "Chocobo Food",
		growTime: 72,
		wiltTime: 24,
		harvestLocation: "Crossbreed Only",
		nodeLevel: "--",
		iLvl: 20
	},
	{
		ID: 12,
		name: "Pixie Plum",
		type: "Fruit",
		growTime: 120,
		wiltTime: 48,
		harvestLocation: "Upper La Noscea (14, 25)",
		nodeLevel: "25",
		iLvl: 21
	},
	{
		ID: 13,
		name: "Ala Mhigan Mustard",
		type: "Herb & Nut",
		growTime: 120,
		wiltTime: 48,
		harvestLocation: "Eastern Thanalan (14, 21)",
		nodeLevel: "25",
		iLvl: 23
	},
	{
		ID: 14,
		name: "Chamomile",
		type: "Herb & Nut",
		growTime: 120,
		wiltTime: 48,
		harvestLocation: "Upper La Noscea (14, 25)",
		nodeLevel: "25",
		iLvl: 24
	},
	{
		ID: 15,
		name: "Millioncorn",
		type: "Vegetable",
		growTime: 120,
		wiltTime: 48,
		harvestLocation: "Eastern Thanalan (14, 20)",
		nodeLevel: "25",
		iLvl: 25
	},
	{
		ID: 16,
		name: "Wizard Eggplant",
		type: "Vegetable",
		growTime: 120,
		wiltTime: 48,
		harvestLocation: "North Shroud (22, 26)",
		nodeLevel: "30",
		iLvl: 27
	},
	{
		ID: 17,
		name: "Midland Cabbage",
		type: "Vegetable",
		growTime: 120,
		wiltTime: 48,
		harvestLocation: "North Shroud (22, 26)",
		nodeLevel: "30",
		iLvl: 28
	},
	{
		ID: 18,
		name: "Linseed",
		type: "Herb & Nut",
		growTime: 120,
		wiltTime: 48,
		harvestLocation: "South Shroud (21, 28)",
		nodeLevel: "35",
		iLvl: 31
	},
	{
		ID: 19,
		name: "Midland Basil",
		type: "Herb & Nut",
		growTime: 120,
		wiltTime: 48,
		harvestLocation: "Eastern La Noscea (26, 29)",
		nodeLevel: "35",
		iLvl: 32
	},
	{
		ID: 20,
		name: "Mandrake",
		type: "Herb & Nut",
		growTime: 120,
		wiltTime: 48,
		harvestLocation: "Eastern La Noscea (26, 29)",
		nodeLevel: "35",
		iLvl: 33
	},
	{
		ID: 21,
		name: "Blood Currant",
		type: "Fruit",
		growTime: 120,
		wiltTime: 48,
		harvestLocation: "South Shroud (21, 28)",
		nodeLevel: "40",
		iLvl: 36
	},
	{
		ID: 22,
		name: "Almond",
		type: "Herb & Nut",
		growTime: 120,
		wiltTime: 48,
		harvestLocation: "Eastern La Noscea (19, 25)",
		nodeLevel: "40",
		iLvl: 40
	},
	{
		ID: 23,
		name: "Mirror Apple",
		type: "Fruit",
		growTime: 120,
		wiltTime: 48,
		harvestLocation: "Coerthas Central Highlands (22, 18)",
		nodeLevel: "45",
		iLvl: 42
	},
	{
		ID: 24,
		name: "Rolanberry",
		type: "Fruit",
		growTime: 120,
		wiltTime: 48,
		harvestLocation: "Upper La Noscea (34, 24)",
		nodeLevel: "45",
		iLvl: 43
	},
	{
		ID: 25,
		name: "Honey Lemon",
		type: "Fruit",
		growTime: 120,
		wiltTime: 48,
		harvestLocation: "Eastern La Noscea (Slot 1 @ 5AM)",
		nodeLevel: "50",
		iLvl: 51
	},
	{
		ID: 26,
		name: "Dzemael Tomato",
		type: "Vegetable",
		growTime: 120,
		wiltTime: 48,
		harvestLocation: "Eastern La Noscea (Slot 1 @ 1PM)",
		nodeLevel: "50",
		iLvl: 51
	},
	{
		ID: 27,
		name: "Prickly Pineapple",
		type: "Fruit",
		growTime: 120,
		wiltTime: 48,
		harvestLocation: "Eastern La Noscea (Slot 1 @ 9AM)",
		nodeLevel: "50",
		iLvl: 51
	},
	{
		ID: 28,
		name: "Apricot",
		type: "Deluxe Fruit",
		growTime: 144,
		wiltTime: 36,
		harvestLocation: "Crossbreed Only",
		nodeLevel: "--",
		iLvl: 55
	},
	{
		ID: 29,
		name: "Royal Kukuru",
		type: "Deluxe Fruit",
		growTime: 144,
		wiltTime: 36,
		harvestLocation: "Crossbreed Only",
		nodeLevel: "--",
		iLvl: 55
	},
	{
		ID: 30,
		name: "Shroud Tea",
		type: "Deluxe Herb & Nut",
		growTime: 144,
		wiltTime: 36,
		harvestLocation: "Crossbreed Only",
		nodeLevel: "--",
		iLvl: 55
	},
	{
		ID: 31,
		name: "Curiel Root",
		type: "Chocobo Food",
		growTime: 120,
		wiltTime: 48,
		harvestLocation: "Crossbreed Only",
		nodeLevel: "--",
		iLvl: 55
	},
	{
		ID: 32,
		name: "Mimett Gourd",
		type: "Chocobo Food",
		growTime: 120,
		wiltTime: 48,
		harvestLocation: "Crossbreed Only",
		nodeLevel: "--",
		iLvl: 55
	},
	{
		ID: 33,
		name: "Pahsana Fruit",
		type: "Chocobo Food",
		growTime: 120,
		wiltTime: 48,
		harvestLocation: "Crossbreed Only",
		nodeLevel: "--",
		iLvl: 55
	},
	{
		ID: 34,
		name: "Azeyma Rose",
		type: "Deluxe Herb & Nut",
		growTime: 144,
		wiltTime: 36,
		harvestLocation: "Crossbreed Only",
		nodeLevel: "--",
		iLvl: 55
	},
	{
		ID: 35,
		name: "Star Anise",
		type: "Deluxe Herb & Nut",
		growTime: 144,
		wiltTime: 36,
		harvestLocation: "Crossbreed Only",
		nodeLevel: "--",
		iLvl: 55
	},
	{
		ID: 36,
		name: "La Noscean Leek",
		type: "Deluxe Vegetable",
		growTime: 144,
		wiltTime: 36,
		harvestLocation: "Crossbreed Only",
		nodeLevel: "--",
		iLvl: 55
	},
	{
		ID: 37,
		name: "Pearl Roselle",
		type: "Deluxe Herb & Nut",
		growTime: 144,
		wiltTime: 36,
		harvestLocation: "Crossbreed Only",
		nodeLevel: "--",
		iLvl: 55
	},
	{
		ID: 38,
		name: "Sylkis Bud",
		type: "Chocobo Food",
		growTime: 120,
		wiltTime: 48,
		harvestLocation: "Crossbreed Only",
		nodeLevel: "--",
		iLvl: 55
	},
	{
		ID: 39,
		name: "Tantalplant",
		type: "Chocobo Food",
		growTime: 120,
		wiltTime: 48,
		harvestLocation: "Crossbreed Only",
		nodeLevel: "--",
		iLvl: 55
	},
	{
		ID: 40,
		name: "Halone Gerbera",
		type: "Deluxe Herb & Nut",
		growTime: 144,
		wiltTime: 36,
		harvestLocation: "Crossbreed Only",
		nodeLevel: "--",
		iLvl: 55
	},
	{
		ID: 41,
		name: "Nymeia Lily",
		type: "Deluxe Herb & Nut",
		growTime: 144,
		wiltTime: 36,
		harvestLocation: "Crossbreed Only",
		nodeLevel: "--",
		iLvl: 55
	},
	{
		ID: 42,
		name: "Jute",
		type: "Deluxe Herb & Nut",
		growTime: 168,
		wiltTime: 24,
		harvestLocation: "Crossbreed Only",
		nodeLevel: "--",
		iLvl: 70
	},
	{
		ID: 43,
		name: "Broombush",
		type: "Deluxe Herb & Nut",
		growTime: 168,
		wiltTime: 24,
		harvestLocation: "Crossbreed Only",
		nodeLevel: "--",
		iLvl: 70
	},
	{
		ID: 44,
		name: "Garlic Jester",
		type: "Minion",
		growTime: 168,
		wiltTime: 24,
		harvestLocation: "Crossbreed Only",
		nodeLevel: "--",
		iLvl: 70
	},
	{
		ID: 45,
		name: "Mandragora Queen",
		type: "Minion",
		growTime: 168,
		wiltTime: 24,
		harvestLocation: "Crossbreed Only",
		nodeLevel: "--",
		iLvl: 70
	},
	{
		ID: 46,
		name: "Thavnairian Onion",
		type: "Chocobo Food",
		growTime: 240,
		wiltTime: 24,
		harvestLocation: "Crossbreed Only",
		nodeLevel: "--",
		iLvl: 70
	},
	{
		ID: 47,
		name: "Eggplant Knight",
		type: "Minion",
		growTime: 168,
		wiltTime: 24,
		harvestLocation: "Crossbreed Only",
		nodeLevel: "--",
		iLvl: 70
	},
	{
		ID: 48,
		name: "Umbrella Fig",
		type: "Deluxe Fruit",
		growTime: 168,
		wiltTime: 24,
		harvestLocation: "Crossbreed Only",
		nodeLevel: "--",
		iLvl: 70
	},
	{
		ID: 49,
		name: "Glazenut",
		type: "Deluxe Fruit",
		growTime: 168,
		wiltTime: 24,
		harvestLocation: "Crossbreed Only",
		nodeLevel: "--",
		iLvl: 70
	},
	{
		ID: 50,
		name: "Tomato King",
		type: "Minion",
		growTime: 168,
		wiltTime: 24,
		harvestLocation: "Crossbreed Only",
		nodeLevel: "--",
		iLvl: 70
	},
	{
		ID: 51,
		name: "Krakka Root",
		type: "Chocobo Food",
		growTime: 72,
		wiltTime: 24,
		harvestLocation: "Material Supplier (Housing District)",
		nodeLevel: "--",
		iLvl: 70
	},
	{
		ID: 52,
		name: "Onion Prince",
		type: "Minion",
		growTime: 168,
		wiltTime: 24,
		harvestLocation: "Crossbreed Only",
		nodeLevel: "--",
		iLvl: 70
	},
	{
		ID: 53,
		name: "Popoto Set",
		type: "Vegetable",
		growTime: 120,
		wiltTime: 48,
		harvestLocation: "Eastern Thanalan (16, 29)",
		nodeLevel: "20",
		iLvl: 18
	},
	{
		ID: 54,
		name: "Dalamud Popoto Set",
		type: "Deluxe Vegetable",
		growTime: 144,
		wiltTime: 36,
		harvestLocation: "Crossbreed Only",
		nodeLevel: "--",
		iLvl: 55
	},
	{
		ID: 55,
		name: "Garlic Cloves",
		type: "Herb & Nut",
		growTime: 120,
		wiltTime: 48,
		harvestLocation: "Western Thanalan (22, 22)",
		nodeLevel: "10",
		iLvl: 11
	},
	{
		ID: 56,
		name: "Pearl Ginger Root",
		type: "Herb & Nut",
		growTime: 120,
		wiltTime: 48,
		harvestLocation: "Eastern Thanalan (14, 21)",
		nodeLevel: "--",
		iLvl: 24
	},
	{
		ID: 57,
		name: "Wild Onion Set",
		type: "Vegetable",
		growTime: 120,
		wiltTime: 48,
		harvestLocation: "Western Thanalan (22, 22)",
		nodeLevel: "15",
		iLvl: 12
	},
	{
		ID: 58,
		name: "Xelphatol Apple",
		type: "Chocobo Food",
		growTime: 48,
		wiltTime: 0,
		harvestLocation: "Hunt Billmaster",
		nodeLevel: "--",
		iLvl: 45
	},
	{
		ID: 59,
		name: "Doman Plum",
		type: "Chocobo Food",
		growTime: 48,
		wiltTime: 0,
		harvestLocation: "Hunt Billmaster",
		nodeLevel: "--",
		iLvl: 45
	},
	{
		ID: 60,
		name: "Mamook Pear",
		type: "Chocobo Food",
		growTime: 48,
		wiltTime: 0,
		harvestLocation: "Hunt Billmaster",
		nodeLevel: "--",
		iLvl: 45
	},
	{
		ID: 61,
		name: "Valfruit",
		type: "Chocobo Food",
		growTime: 48,
		wiltTime: 0,
		harvestLocation: "Hunt Billmaster",
		nodeLevel: "--",
		iLvl: 45
	},
	{
		ID: 62,
		name: "O'Ghomoro Berry",
		type: "Chocobo Food",
		growTime: 48,
		wiltTime: 0,
		harvestLocation: "Hunt Billmaster",
		nodeLevel: "--",
		iLvl: 45
	},
	{
		ID: 63,
		name: "Cieldalaes Pineapple",
		type: "Chocobo Food",
		growTime: 48,
		wiltTime: 0,
		harvestLocation: "Hunt Billmaster",
		nodeLevel: "--",
		iLvl: 45
	},
	{
		ID: 64,
		name: "Han Lemon",
		type: "Chocobo Food",
		growTime: 48,
		wiltTime: 0,
		harvestLocation: "Hunt Billmaster",
		nodeLevel: "--",
		iLvl: 45
	},
	{
		ID: 65,
		name: "Blood Pepper",
		type: "Deluxe Vegetable",
		growTime: 168,
		wiltTime: 24,
		harvestLocation: "Crossbreed Only",
		nodeLevel: "--",
		iLvl: 160
	},
	{
		ID: 66,
		name: "Old World Fig",
		type: "Fruit",
		growTime: 120,
		wiltTime: 48,
		harvestLocation: "Dravanian Forelands (27, 14) (Slot 1 @ 2AM/PM)",
		nodeLevel: "50",
		iLvl: 115
	},
	{
		ID: 67,
		name: "Chive",
		type: "Deluxe Vegetable",
		growTime: 120,
		wiltTime: 48,
		harvestLocation: "Crossbreed Only",
		nodeLevel: "--",
		iLvl: 136
	},
	{
		ID: 68,
		name: "Pearl Sprout",
		type: "Vegetable",
		growTime: 120,
		wiltTime: 48,
		harvestLocation: "Coerthas Western Highlands (31, 20) (Slot 2 @ 10AM/PM)",
		nodeLevel: "55",
		iLvl: 125
	},
	{
		ID: 69,
		name: "Coerthan Tea",
		type: "Herb & Nut",
		growTime: 120,
		wiltTime: 48,
		harvestLocation: "Coerthas Western Highlands (31, 20) (Slot 3 @ 10AM/PM)",
		nodeLevel: "55",
		iLvl: 125
	},
	{
		ID: 70,
		name: "Althyk Lavender",
		type: "Herb & Nut",
		growTime: 22,
		wiltTime: 0,
		harvestLocation: "GC Seals / Resident Caretaker",
		nodeLevel: "--",
		iLvl: 150
	},
	{
		ID: 71,
		name: "Voidrake",
		type: "Herb & Nut",
		growTime: 13,
		wiltTime: 0,
		harvestLocation: "GC Seals / Resident Caretaker",
		nodeLevel: "--",
		iLvl: 55
	},
	{
		ID: 72,
		name: "Oldrose",
		type: "Flowerpot",
		growTime: 24,
		wiltTime: 0,
		harvestLocation: "Material Supplier (Flowerpot Only)",
		nodeLevel: "--",
		iLvl: 1
	},
	{
		ID: 73,
		name: "Firelight",
		type: "Elemental",
		growTime: 18,
		wiltTime: 0,
		harvestLocation: "Middle La Noscea (23, 18)",
		nodeLevel: "15",
		iLvl: 1
	},
	{
		ID: 74,
		name: "Icelight",
		type: "Elemental",
		growTime: 18,
		wiltTime: 0,
		harvestLocation: "Western La Noscea (31, 29)",
		nodeLevel: "20",
		iLvl: 1
	},
	{
		ID: 75,
		name: "Windlight",
		type: "Elemental",
		growTime: 18,
		wiltTime: 0,
		harvestLocation: "Central Shroud (21, 23)",
		nodeLevel: "15",
		iLvl: 1
	},
	{
		ID: 76,
		name: "Earthlight",
		type: "Elemental",
		growTime: 18,
		wiltTime: 0,
		harvestLocation: "Lower La Noscea (25, 25)",
		nodeLevel: "15",
		iLvl: 1
	},
	{
		ID: 77,
		name: "Levinlight",
		type: "Elemental",
		growTime: 18,
		wiltTime: 0,
		harvestLocation: "Central Thanalan (25, 19)",
		nodeLevel: "15",
		iLvl: 1
	},
	{
		ID: 78,
		name: "Waterlight",
		type: "Elemental",
		growTime: 18,
		wiltTime: 0,
		harvestLocation: "Western Thanalan (24, 18)",
		nodeLevel: "15",
		iLvl: 1
	},
	{
		ID: 79,
		name: "Viola",
		type: "Flowerpot",
		growTime: 24,
		wiltTime: 0,
		harvestLocation: "Material Supplier (Flowerpot Only)",
		nodeLevel: "--",
		iLvl: 1
	},
	{
		ID: 80,
		name: "Cloud Acorn",
		type: "Herb & Nut",
		growTime: 24,
		wiltTime: 0,
		harvestLocation: "Material Supplier (Housing District)",
		nodeLevel: "--",
		iLvl: 148
	},
	{
		ID: 81,
		name: "Shroud Cherry",
		type: "Flowerpot",
		growTime: 24,
		wiltTime: 0,
		harvestLocation: "Material Supplier (Flowerpot Only)",
		nodeLevel: "--",
		iLvl: 1
	},
	{
		ID: 82,
		name: "Daisy",
		type: "Flowerpot",
		growTime: 24,
		wiltTime: 0,
		harvestLocation: "Material Supplier (Flowerpot Only)",
		nodeLevel: "--",
		iLvl: 1
	},
	{
		ID: 83,
		name: "Brightlily",
		type: "Flowerpot",
		growTime: 24,
		wiltTime: 0,
		harvestLocation: "Material Supplier (Flowerpot Only)",
		nodeLevel: "--",
		iLvl: 1
	},
	{
		ID: 84,
		name: "Cloudsbreath",
		type: "Deluxe Herb & Nut",
		growTime: 120,
		wiltTime: 48,
		harvestLocation: "Crossbreed Only / Airship Ventures (Rank 50)",
		nodeLevel: "--",
		iLvl: 1
	},
	{
		ID: 85,
		name: "Royal Fern",
		type: "Deluxe Vegetable",
		growTime: 120,
		wiltTime: 24,
		harvestLocation: "Crossbreed Only",
		nodeLevel: "--",
		iLvl: 1
	},
	{
		ID: 86,
		name: "Tulip",
		type: "Flowerpot",
		growTime: 24,
		wiltTime: 0,
		harvestLocation: "Material Supplier (Flowerpot Only)",
		nodeLevel: "--",
		iLvl: 1
	},
	{
		ID: 87,
		name: "Dahlia",
		type: "Flowerpot",
		growTime: 24,
		wiltTime: 0,
		harvestLocation: "Material Supplier (Flowerpot Only)",
		nodeLevel: "--",
		iLvl: 1
	},
	{
		ID: 88,
		name: "Arum",
		type: "Flowerpot",
		growTime: 24,
		wiltTime: 0,
		harvestLocation: "Material Supplier (Flowerpot Only)",
		nodeLevel: "--",
		iLvl: 1
	},
	{
		ID: 89,
		name: "Lily of the Valley",
		type: "Flowerpot",
		growTime: 24,
		wiltTime: 0,
		harvestLocation: "Material Supplier (Flowerpot Only)",
		nodeLevel: "--",
		iLvl: 1
	},
	{
		ID: 90,
		name: "Hydrangea",
		type: "Flowerpot",
		growTime: 24,
		wiltTime: 0,
		harvestLocation: "Material Supplier (Flowerpot Only)",
		nodeLevel: "--",
		iLvl: 1
	},
	{
		ID: 91,
		name: "Campanula",
		type: "Flowerpot",
		growTime: 24,
		wiltTime: 0,
		harvestLocation: "Material Supplier (Flowerpot Only)",
		nodeLevel: "--",
		iLvl: 1
	},
	{
		ID: 92,
		name: "Hyacinth",
		type: "Flowerpot",
		growTime: 24,
		wiltTime: 0,
		harvestLocation: "Material Supplier (Flowerpot Only)",
		nodeLevel: "--",
		iLvl: 1
	},
	{
		ID: 93,
		name: "Allagan Melon",
		type: "Minion",
		growTime: 168,
		wiltTime: 24,
		harvestLocation: "Material Supplier (Housing District)",
		nodeLevel: "--",
		iLvl: 1
	},
	{
		ID: 94,
		name: "Cosmos",
		type: "Flowerpot",
		growTime: 24,
		wiltTime: 0,
		harvestLocation: "Material Supplier (Flowerpot Only)",
		nodeLevel: "--",
		iLvl: 1
	},
	{
		ID: 95,
		name: "Carnation",
		type: "Flowerpot",
		growTime: 24,
		wiltTime: 0,
		harvestLocation: "Material Supplier (Flowerpot Only)",
		nodeLevel: "--",
		iLvl: 1
	},
	{
		ID: 96,
		name: "Moth Orchid",
		type: "Flowerpot",
		growTime: 24,
		wiltTime: 0,
		harvestLocation: "Material Supplier (Flowerpot Only)",
		nodeLevel: "--",
		iLvl: 1
	}
];