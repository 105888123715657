import firebase from 'firebase/app';

import 'firebase/auth';
import 'firebase/firestore'

import { useAuthState } from 'react-firebase-hooks/auth';

var firebaseConfig = {
    apiKey: "AIzaSyAG25CHhGKf-b5_j-Lr8uH1DLMVdWMTCCM",
    authDomain: "garden-patch.firebaseapp.com",
    projectId: "garden-patch",
    storageBucket: "garden-patch.appspot.com",
    messagingSenderId: "1040443318753",
    appId: "1:1040443318753:web:81d30fc3816e2bf177b2a0",
    measurementId: "G-9FQD0ZBT3H"
};
  // Initialize Firebase
firebase.initializeApp(firebaseConfig);

export { firebase };
export const auth = firebase.auth();
export const firestore = firebase.firestore();