import { IonButton, IonHeader, IonIcon, IonImg, IonLabel, IonModal, IonText, IonThumbnail, IonTitle, IonToolbar } from '@ionic/react';
import React, { useState, useCallback, useEffect } from 'react';
import { PlantInfo } from '../../models/Patches';

import styles from './PlantInfoModal.module.css'

interface PlantInfoModalProps {
    plant: PlantInfo | null | never;
    showModal: boolean;
    setShowModal(arg: boolean): void;
}

const PlantInfoModal: React.FC<PlantInfoModalProps> = ({ plant, showModal, setShowModal }) => {

    const convertHours = (hours: number) => {
        if (hours === -1) return '--';
        if (hours < 24) {
          return `${hours} hours`;
        }
        else if (hours % 24 === 0) {
          return `${hours/24} days`;
        }
        else {
          return `${Math.floor(hours/24)} days, ${hours % 24} hours`
        }
      };

    return (
        <IonModal isOpen={showModal} onWillDismiss={() => setShowModal(false)} cssClass={styles.modal}>
        { plant && <div>
            <IonHeader>
            <IonToolbar>
                <IonTitle>{plant.name}</IonTitle>
            </IonToolbar>
            </IonHeader>
            <div className={styles.plantModal}>
            <IonText color="dark" className={styles.plantAttributes}>
                <IonLabel className={styles.label}>Type: {plant.type}</IonLabel>
                <IonLabel className={styles.label}>Grow time: {convertHours(plant.growTime)}</IonLabel>
                <IonLabel className={styles.label}>Wilt time: {convertHours(plant.wiltTime)}</IonLabel>
                <IonLabel className={styles.label}>Harvest location: {plant.harvestLocation}</IonLabel>
                <IonLabel className={styles.label}>Node level: {plant.nodeLevel}</IonLabel>
                <IonLabel className={styles.label}>iLvl: {plant.iLvl}</IonLabel>
            </IonText>
            <IonThumbnail slot="start" className={styles.plantIcon}>
                    <img src={`assets/plantIcons/${plant.ID}.png`} />
            </IonThumbnail>
            </div>
        </div> }
        <IonButton onClick={() => setShowModal(false)}>Back</IonButton>
      </IonModal>


    );
}

export default PlantInfoModal;