
import { plantData } from '../plants/PlantData'

export const HOUR = 3600000;

export enum PlantState {
    IsEmpty = "isEmpty",
    IsGrowing = "isGrowing",
    IsGrown = "isGrown",
    IsWilting = "isWilting",
    IsDead = "isDead"
  }

  export enum PatchType {
    Deluxe = "Deluxe",
    Ooblong = "Ooblong",
    Round = "Round"
  }

  export interface PlantInfo {
    ID: number;
    name: string;
    type: string;
    growTime: number;
    wiltTime: number;
    harvestLocation: string;
    nodeLevel: string;
    iLvl: number;
  }

  export const PatchConverter = {
    toFirestore: function(patch: GardenPatch) {
      return {
        ID: patch.ID,
        name: patch.name,
        type: patch.type,
        createdAt: patch.createdAt,
        beds: patch.beds.map((bed) => {
          return {
            ID: bed.ID,
            plantID: bed.plantID,
            waterTime: bed.waterTime,
            fertilizeCount: bed.fertilizeCount,
            fertilizeTime: bed.fertilizeTime,
            plantTime: bed.plantTime,
            growTimeReduction: bed.growTimeReduction,
          }
        })
      }
    },
    fromFirestore: function(snapshot: any, options: any){
      const data = snapshot.data(options);
      let patch = new GardenPatch(data.ID, data.name, data.type);
      patch.ID = data.ID;
      patch.createdAt = data.createdAt;
      patch.beds = data.beds.map((bed: any) => {
        return Object.setPrototypeOf(bed, PlantBed.prototype);
      });
      return patch;
    }
  }

  export class PlantBed {
    constructor(ID: number, plantID: number) {
      this.ID = ID;
      this.plantID = plantID;
      this.waterTime = null;
      this.fertilizeCount = 0;
      this.fertilizeTime = null;
      this.plantTime = null;
      this.growTimeReduction = 0;
      this.isLocked = false;
    };

    ID: number;
    plantID: number;
    waterTime: number | null;
    fertilizeTime: number | null;
    fertilizeCount: number;
    growTimeReduction: number;
    plantTime: number | null;
    isLocked: boolean;

    private plantInfo: any;

    getPlantInfo() {
      if (!this.plantInfo) this.plantInfo = plantData.find(({ ID }) => ID == this.plantID);
      return this.plantInfo;
    }

    plantState(time: number) {
      if (this.plantID === -1) return PlantState.IsEmpty;
      if (this.isGrown(time)) return PlantState.IsGrown;
      if (this.isDead(time)) return PlantState.IsDead;
      if (this.isWilting(time)) return PlantState.IsWilting;
      else return PlantState.IsGrowing;
    }

    isGrown(time: number) {
      if (this.plantID === -1 || !this.plantTime) return false;
      let plantInfo = this.getPlantInfo();
      let growTime = plantInfo?.growTime! * HOUR - this.growTimeReduction;
      let fullyGrownTime = this.plantTime! + growTime;
      return fullyGrownTime < time &&
        fullyGrownTime < this.waterTime! + (plantInfo?.wiltTime! + 24) * HOUR;
    }

    isWilting(time: number) {
      if (this.plantID === -1 || !this.plantTime) return false;
      let plantInfo = this.getPlantInfo();
      if (plantInfo.wiltTime === 0) return false;
      let growTime = plantInfo?.growTime! * HOUR - this.growTimeReduction;
      let fullyGrownTime = this.plantTime! + growTime;
      return time > this.waterTime! + plantInfo?.wiltTime! * HOUR &&
        Math.min(time, fullyGrownTime) < this.waterTime! + (plantInfo?.wiltTime! + 24) * HOUR;
    }

    isDead(time: number) {
      if (this.plantID === -1 || !this.plantTime) return false;
      let plantInfo = this.getPlantInfo();
      let growTime = plantInfo?.growTime! * HOUR - this.growTimeReduction;
      let fullyGrownTime = this.plantTime! + growTime;
      return Math.min(time, fullyGrownTime) > this.waterTime! + (plantInfo?.wiltTime! + 24) * HOUR;
    }

    remainingGrowTime(time: number) {
      let plantInfo = this.getPlantInfo();
      let growTime = plantInfo?.growTime! * HOUR - this.growTimeReduction;
      return Math.max(this.plantTime! + growTime - time, 0);
    }

    remainingFertilizeTime(time: number) {
      return Math.max(0, this.fertilizeTime!- time + HOUR);
    }

    remainingWaterTime(time: number) {
      let plantInfo = this.getPlantInfo();
      let wiltTime = plantInfo?.wiltTime! * HOUR;
      if (wiltTime === 0) return -1;
      return Math.max(0, this.waterTime!- time + 24 * HOUR);
    }

    remainingWiltTime(time: number) {
      let plantInfo = this.getPlantInfo();
      let wiltTime = plantInfo?.wiltTime! * HOUR;
      if (wiltTime === 0) return -1;
      return Math.max(0, this.waterTime! - time + wiltTime + 24 * HOUR);
    }

    getFertilizeProgress(time: number) {
      if (!this.fertilizeTime) return 0;
      let elapsed = time - this.fertilizeTime!;
      return Math.max(0, Math.min(1 - elapsed/HOUR, 1));
    }

    getGrowProgress(time: number) {
      let plantInfo = this.getPlantInfo();
      let growTime = plantInfo?.growTime! * HOUR - this.growTimeReduction;
      let elapsed = time - this.plantTime!;
      return Math.max(0, Math.min(elapsed/growTime, 1));
    }

    getWateringProgress(time: number) {
      let plantInfo = this.getPlantInfo();
      let elapsed = time - this.waterTime!;
      return Math.max(0, Math.min(1 - elapsed/(24 * HOUR), 1))
    }

    getWiltingProgress(time: number) {
      let plantInfo = this.getPlantInfo();
      let wiltTime = plantInfo?.wiltTime! * HOUR;
      let elapsed = time - this.waterTime!;
      return Math.max(0, Math.min(1 - elapsed/(wiltTime + 24 * HOUR), 1))
    }

    setIsGrown(time: number) {
      this.plantTime! -= this.remainingGrowTime(time);
    }

    setPlant(id: number) {
      this.plantID = id;
      this.waterTime = Date.now();
      this.plantTime = Date.now();
      this.fertilizeCount = 0;
      this.fertilizeTime = null;
      this.growTimeReduction = 0;
      this.isLocked = false;
    }

    isFertilizeAvailable(time: number) {
      let plantState = this.plantState(time);
      if (plantState === PlantState.IsGrowing || plantState === PlantState.IsWilting)
      {
        return time - this.fertilizeTime! > HOUR;
      }
      return false;
    }

    waterPlant(time: number) {
      let plantState = this.plantState(time);
      if(plantState === PlantState.IsGrowing || plantState === PlantState.IsWilting) {
        this.waterTime = time;
      }
    }

    fertilizePlant(time: number) {
      let plantState = this.plantState(time);
      if(plantState === PlantState.IsGrowing || plantState === PlantState.IsWilting) {
        this.fertilizeCount += 1;
        this.fertilizeTime = time;
        this.growTimeReduction += 0.01 * this.remainingGrowTime(time);
      }
    }

    clearPlant() {
      this.plantID = -1;
      this.plantTime = null;
      this.waterTime = null;
      this.fertilizeCount = 0;
      this.fertilizeTime = null;
      this.plantInfo = null;
      this.growTimeReduction = 0;
      this.isLocked = false;
    }
  }

  export class GardenPatch {
    ID: string;
    name: string;
    type: PatchType;
    beds: PlantBed[];
    createdAt: Date;

    constructor(id: string, name: string, type: PatchType) {
      this.ID = id;
      this.name = name;
      this.type = type;
      this.beds = [];
      this.createdAt = new Date();
      switch (type) {
        case PatchType.Deluxe:
          this.beds = [
            new PlantBed(1, -1),
            new PlantBed(2, -1),
            new PlantBed(3, -1),
            new PlantBed(4, -1),
            new PlantBed(5, -1),
            new PlantBed(6, -1),
            new PlantBed(7, -1),
            new PlantBed(8, -1)
          ]
          break;
        case PatchType.Ooblong:
        this.beds = [
          new PlantBed(1, -1),
          new PlantBed(2, -1),
          new PlantBed(3, -1),
          new PlantBed(4, -1),
          new PlantBed(5, -1),
          new PlantBed(6, -1),
        ]
        break;
      case PatchType.Round:
        this.beds = [
          new PlantBed(1, -1),
          new PlantBed(2, -1),
          new PlantBed(3, -1),
          new PlantBed(4, -1)
        ]
        break;
      }
    };

    setPlantBed(index: number, plant: number) {
      this.beds[index].plantID = plant;
      this.beds[index].plantTime = Date.now();
      this.beds[index].waterTime = Date.now();
    };

    removePlant(index: number) {
      this.beds[index].clearPlant();
    }
  }