import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { Capacitor } from '@capacitor/core';
import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonText, IonSearchbar, IonList, IonItem, IonButton, IonModal, IonThumbnail , IonLabel, IonImg, IonIcon } from '@ionic/react';
import './PlantsTab.css';

import { plantData } from '../plants/PlantData'
import PlantInfoModal from '../components/plant-modal/PlantInfoModal';
import { star } from 'ionicons/icons';
import { firestore, auth } from '../firebase';
import { PlantInfo } from '../models/Patches';
import { useFavPlants } from '../hooks/useFavPlants';

const PlantsTab: React.FC = () => {
  const [searchText, setSearchText] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [selectedPlant, setSelectedPlant] = useState(null);

  const [favPlants, setFavPlants] = useFavPlants();
  const user = firestore.collection('users').doc(auth.currentUser?.uid);

  const [plants, setPlants] = useState(plantData);
  useEffect(() => {
    console.log('update');
    user.set({ favPlants: favPlants }, {merge: true});
    setPlants([...plantData.filter(v => favPlants.includes(v.ID as never)).sort((p1, p2) => p1.name.localeCompare(p2.name)), ...plantData.filter(v => !favPlants.includes(v.ID as never)).sort((p1, p2) => p1.name.localeCompare(p2.name))]);
  }, [favPlants])

  const addToFavourites = useCallback((e: React.MouseEvent<HTMLElement>, plant: PlantInfo) => {
    e.preventDefault();
    e.stopPropagation();
    if(favPlants.includes(plant.ID as never)) setFavPlants(favPlants.filter((v: number) => v != plant.ID));
    else setFavPlants([...favPlants, plant.ID] as any);
  }, [favPlants]);

  const onPlantSelection = useCallback((plant) => {
    setSelectedPlant(plant);
    setShowModal(true);
  }, []);

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      Capacitor.addListener!('App', 'backButton', e => {
        if (showModal) {
          setShowModal(false);
        }
      })
    }
  });

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Plants</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
      <PlantInfoModal showModal={showModal} setShowModal={setShowModal} plant={selectedPlant}/>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Plants</IonTitle>
          </IonToolbar>
        </IonHeader>
        <IonSearchbar value={searchText} onIonChange={e => setSearchText(e.detail.value!)} placeholder="Enter plant name..."></IonSearchbar>
        <IonList className="plantList">
          { plants.filter((plant) => searchText === '' || plant.name.toLowerCase().includes(searchText.toLowerCase())).map((plant) => {
            return (
            <IonItem button onClick={() => onPlantSelection(plant)} key={plant.ID}>
              <IonThumbnail slot="start">
                <IonImg src={`assets/plantIcons/${plant.ID}.png`}/>
              </IonThumbnail>
              <IonLabel>{plant.name}</IonLabel>
              <IonButton slot="end" color="transparent" onClick={(e) => addToFavourites(e, plant as PlantInfo)}>
                  <IonIcon slot="icon-only" icon={star} color={favPlants.includes(plant.ID as never) ? "warning" : "medium"} />
              </IonButton>
            </IonItem>
          )})}
        </IonList>
      </IonContent>
    </IonPage>
  );
};

export default PlantsTab;
