import React, { useEffect, useState } from "react";
import { firestore, auth } from '../firebase';

const TimeContext = React.createContext<any>({});

export function TimeProvider({children}: {children: any}) {
    const [time, setTime] = useState<number>(Date.now());

    useEffect(() => {
        const interval = setInterval(() => setTime(Date.now()), 1000);
        return () => {
          clearInterval(interval);
        };
      }, []);

    return <TimeContext.Provider value={{ time }}>{children}</TimeContext.Provider>
}

export function useTime() {
    const context = React.useContext(TimeContext);
    return context.time;
}