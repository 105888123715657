import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonBackdrop,
  IonButton,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
  useIonLoading,
  useIonRouter,
  useIonToast,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { ellipse, flower, leaf, settings, square, triangle, water, partlySunny } from 'ionicons/icons';
import GardenPatchesTab from './pages/GardenPatchesTab';
import PlantsTab from './pages/PlantsTab';
import SettingsTab from './pages/SettingsTab';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './App.css'
import { useEffect, useState } from 'react';
import { useAuthState } from 'react-firebase-hooks/auth';


import './firebase'
import { auth, firebase, firestore } from './firebase';
import { ContextProviders } from './components/ContextProviders/ContextProviders';

const App: React.FC = () => {

  const [user, loading] = useAuthState(auth);
  const [presentToast, dismissToast] = useIonToast();

  useEffect(() => {
    if(!loading && user) {
      presentToast({
        message: `Welcome, ${user?.displayName}!`,
        duration: 2000,
        position: "top",
        color: "primary",
      });
    }
  }, [user, loading])

  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider)
      .then((data) => {
        firestore.collection('users').doc(data.user?.uid).get().then((doc) => {
          if(doc && doc.exists) {
            if ('favPlants' in doc.data()!) {
              doc.ref.set({ lastLogin: new Date() }, {merge: true});
            }
            else doc.ref.set({ lastLogin: new Date(), favPlants: [] }, {merge: true});
          }
          else doc.ref.set({ createdAt: new Date(), lastLogin: new Date(), favPlants: [] }, {merge: true});
        });
      });
  };

  return (
    <IonApp>
      {!loading && !user && <div className="loginContainer">
          <div className="textContainer">
            <div className="title">Garden Patches</div>
            <div className="subtitle">Your daily FFXIV gardening helper</div>
          <IonButton className="signInButton" onClick={signInWithGoogle}>Sign in with Google</IonButton>
          </div>
          <IonBackdrop className="backdrop" />
        </div>
      }
      <IonReactRouter>
        <ContextProviders>
          <IonTabs>
            <IonRouterOutlet>
              <Route exact path="/home">
                <GardenPatchesTab />
              </Route>
              <Route exact path="/plants">
                <PlantsTab />
              </Route>
              <Route path="/settings">
                <SettingsTab />
              </Route>
              <Route exact path="/">
                <Redirect to="/home" />
              </Route>
            </IonRouterOutlet>
            <IonTabBar slot="bottom">
              <IonTabButton tab="GardenPatchesTab" href="/home">
                <IonIcon icon={partlySunny} />
                <IonLabel>Garden Patches</IonLabel>
              </IonTabButton>
              <IonTabButton tab="PlantsTab" href="/plants">
                <IonIcon icon={leaf} />
                <IonLabel>Plants</IonLabel>
              </IonTabButton>
              <IonTabButton tab="SettingsTab" href="/settings">
                <IonIcon icon={settings} />
                <IonLabel>Settings</IonLabel>
              </IonTabButton>
            </IonTabBar>
          </IonTabs>
        </ContextProviders>
      </IonReactRouter>
    </IonApp>
  );
}


export default App;
