import { IonIcon, IonImg, IonProgressBar, IonThumbnail } from '@ionic/react';
import React, { useState, useCallback, useEffect } from 'react';
import { GardenPatch, PlantBed, PatchType, PlantState } from '../../../models/Patches';
import { add, water, sparkles, trash, flash, leaf, flower } from 'ionicons/icons';

import { plantData } from '../../../plants/PlantData'

import './GardenPatchPreview.css';
import IconProgressIndicator from '../../IconProgressIndicator/IconProgressIndicator';
import { useTime } from '../../../hooks/useTime';

const GardenPatchPreview: React.FC<{patch: GardenPatch}> = ({ patch }) => {
    const time = useTime();

    const getPlantIcon = useCallback((bed: PlantBed) => {
        if (bed.plantID === -1) return (
            <div className="emptyBed"></div>
        );
        else {
            return (
                <>
                    <div className={`plantIconBed ${bed.plantState(time)}`}>
                        <img decoding="sync" src={`assets/plantIcons/${bed.plantID}.png`} />
                    </div>
                    { bed.plantState(time) !== PlantState.IsDead && bed.plantState(time) !== PlantState.IsGrown &&
                    <div className="plantProgressContainer">
                        <IonProgressBar value={bed!.getFertilizeProgress(time)} color="warning"/>
                        { bed.remainingWaterTime(time) !== -1 && <IonProgressBar value={bed!.getWateringProgress(time)} color="secondary"/> }
                        <IonProgressBar value={bed!.getGrowProgress(time)} color="success"/>
                    </div>
                    }
                </>
            )
        }
    }, [time]);

    return (
        <div className={`${patch.type}PreviewWrapper`}>
            <div className="outerBorder">
                <div className="innerBorder">
                    <div className={`${patch.type}PatchGrid`}>
                            { patch.beds.map((bed: PlantBed, index) => {
                            return (
                                <div className="clickableBed" key={index}>
                                    <div className={`${patch.type}PatchBed`}>{ getPlantIcon(bed) }</div>
                                </div>
                            );
                        })}
                        { patch.type === PatchType.Deluxe &&
                                <div className="clickableBed"><div className={`${patch.type}PatchBed`}/> </div>}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default GardenPatchPreview;