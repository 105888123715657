import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonModal, IonNote, IonPopover, IonProgressBar, IonSearchbar, IonThumbnail, IonTitle, IonToolbar, useIonAlert, useIonToast } from '@ionic/react';
import { water, sparkles, trash, leaf, sad, ellipsisVertical, create, refresh, search, star } from 'ionicons/icons';
import React, { useState, useCallback, useEffect } from 'react';
import { firestore, auth } from '../../../firebase';
import { useFavPlants } from '../../../hooks/useFavPlants';
import { useTime } from '../../../hooks/useTime';
import { GardenPatch, PlantBed, PatchType, PlantState, PlantInfo } from '../../../models/Patches';
import { plantData } from '../../../plants/PlantData'
import { getDurationString } from '../../../utils/timeUtils';
import PlantInfoModal from '../../plant-modal/PlantInfoModal';

import styles from './GardenPatchPage.module.css'

const GardenPatchPage: React.FC<{patch: GardenPatch, saveChanges: (patch: GardenPatch)=> void, dismiss: ()=> void }> = ({ patch, saveChanges, dismiss }) => {
    const time = useTime();
    const [selectedBed, setSelectedBed] = useState<PlantBed | null>(null);
    const [searchText, setSearchText] = useState<string>('');
    const [showModal, setShowModal] = useState(false);
    const [showPlantInfoModal, setShowPlantInfoModal] = useState(false);
    const [presentToast, dismissToast] = useIonToast();

    const [showMoreOptionsPopover, setShowMoreOptionsPopover] = useState({ showPopover: false, event: undefined });

    const [presentNameChange, dismissNameChange] = useIonAlert();

    const [favPlants, setFavPlants] = useFavPlants();
    const user = firestore.collection('users').doc(auth.currentUser?.uid);

    const [plants, setPlants] = useState(plantData);

    const getPlantIcon = useCallback((bed: PlantBed) => {
        if (bed.plantID === -1) return (
            <div className={styles.emptyBed}></div>
        );
        else {
            return (<>
                    <div className={[styles.plantIconBed, getPlantStateStyle(bed.plantState(time)) ].join(" ")}>
                    { bed.plantState(time) !== PlantState.IsDead && bed.plantState(time) !== PlantState.IsGrown &&
                    <>
                        {/* <IconProgressIndicator className={styles.fertilizeProgressIcon} fullColor="fertilize" backgroundColor="medium" progressColor="warning" value={bed.getFertilizeProgress(time)} icon={flash} size="32px"/>
                        { bed.remainingWaterTime(time) !== -1 && <IconProgressIndicator className={styles.waterProgressIcon} fullColor="water" backgroundColor="medium" progressColor="water" value={1 - bed.getWateringProgress(time)} icon={water} size="32px"/> }
                        <IconProgressIndicator className={styles.growProgressIcon} fullColor="success" backgroundColor="medium" progressColor="success" value={1 - bed.getGrowProgress(time)} icon={flower} size="32px"/> */}
                    </>}
                        <img className={styles.plantImage} decoding="sync" src={`assets/plantIcons/${bed.plantID}.png`} />
                    </div>
                    { bed.plantState(time) !== PlantState.IsDead && bed.plantState(time) !== PlantState.IsGrown &&<div className={styles.plantProgressContainer}>
                    <IonProgressBar value={bed!.getFertilizeProgress(time)} color="warning"/>
                        { bed.remainingWaterTime(time) !== -1 && <IonProgressBar value={bed!.getWateringProgress(time)} color="secondary"/> }
                        <IonProgressBar value={bed!.getGrowProgress(time)} color="success"/>
                    </div> }
                </>
            )
        }
    }, [time, patch]);

    const getPlantStateStyle = useCallback((state) => {
        switch (state) {
            case PlantState.IsGrown:
                return styles.isGrown;
            case PlantState.IsDead:
                return styles.isDead;
            case PlantState.IsWilting:
                return styles.isWilting;
            case PlantState.IsGrowing:
                return styles.isGrowing;
            default:
                return "";
        }
    }, []);

    const getGridStyle = useCallback(() => {
        switch (patch.type) {
            case PatchType.Deluxe:
                return styles.DeluxePatchGrid;
            case PatchType.Ooblong:
                return styles.OoblongPatchGrid;
            case PatchType.Round:
                return styles.RoundPatchGrid;
        }
    }, [patch]);

    const waterPlant = useCallback(() => {
        selectedBed!.waterPlant(time);
        presentToast({
            message: `Watered ${selectedBed!.getPlantInfo().name}!`,
            duration: 1000,
            position: "top",
            color: "tertiary"
        });
        saveChanges(patch);
    }, [selectedBed, time]);

    const fertilizePlant = useCallback(() => {
        selectedBed!.fertilizePlant(time);
        presentToast({
            message: `Fertilized ${selectedBed!.getPlantInfo().name}!`,
            duration: 1000,
            position: "top",
            color: "primary"
        });
        saveChanges(patch);
    }, [selectedBed, time]);

    const waterAllPlants = useCallback(() => {
        patch.beds.map((bed) => bed.waterPlant(time));
        presentToast({
            message: `Watered all plants I could!`,
            duration: 1000,
            position: "top",
            color: "tertiary"
        });
        saveChanges(patch);
    }, [time]);

    const fertilizeAllPlants = useCallback(() => {
        patch.beds.map((bed) => bed.fertilizePlant(time));
        presentToast({
            message: `Fertilized all plants I could!`,
            duration: 1000,
            position: "top",
            color: "primary"
        });
        saveChanges(patch);
    }, [time]);

    const removePlant = useCallback(() => {
        presentToast({
            message: `Removed ${selectedBed!.getPlantInfo().name}!`,
            duration: 1000,
            position: "top",
            color: "danger"
        });
        selectedBed!.clearPlant();
        saveChanges(patch);
    }, [selectedBed, time]);

    const replantPlant = useCallback(() => {
        let plantID = selectedBed!.plantID;
        selectedBed!.clearPlant();
        selectedBed?.setPlant(plantID);
        presentToast({
            message: `Replanted ${selectedBed!.getPlantInfo().name}!`,
            duration: 1000,
            position: "top",
            color: "success"
        });
        saveChanges(patch);
    }, [selectedBed, time]);

    const setIsGrown = useCallback(() => {
        selectedBed!.setIsGrown(time);
        presentToast({
            message: `Set ${selectedBed!.getPlantInfo().name}! as grown!`,
            duration: 1000,
            position: "top",
            color: "success"
        });
        saveChanges(patch);
    }, [selectedBed, time]);

    const addPlant = useCallback(() => {
        setShowModal(true);
    }, []);

    useEffect(() => {
        if (favPlants.length != 0) user.set({ favPlants: favPlants }, {merge: true});
        console.log(favPlants);
        setPlants([...plantData.filter(v => favPlants.includes(v.ID as never)).sort((p1, p2) => p1.name.localeCompare(p2.name)), ...plantData.filter(v => !favPlants.includes(v.ID as never)).sort((p1, p2) => p1.name.localeCompare(p2.name))]);
    }, [favPlants])

    const addToFavourites = useCallback((e: React.MouseEvent<HTMLElement>, plant: PlantInfo) => {
        e.preventDefault();
        e.stopPropagation();
        if(favPlants.includes(plant.ID as never)) setFavPlants(favPlants.filter((v: number) => v != plant.ID));
        else setFavPlants([...favPlants, plant.ID] as any);
    }, [favPlants]);

    useEffect(() => {
        patch = Object.setPrototypeOf(patch, GardenPatch.prototype);
        patch.beds.map((bed) => bed = Object.setPrototypeOf(bed, PlantBed.prototype));
    }, [])

    const getPlantSelectionModal = useCallback(() => {
        return (
            <div className={styles.plantPageBody}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle className={styles.title}>Select a plant</IonTitle>
                        <IonSearchbar className={styles.searchbar} onIonChange={e => setSearchText(e.detail.value!)} placeholder="Enter plant name..."></IonSearchbar>
                    </IonToolbar>
                </IonHeader>
                <IonContent>
                    <IonList className="plantList">
                    { plants.filter((plant) => searchText === '' || plant.name.toLowerCase().includes(searchText.toLowerCase())).map((plant) => {
                        return (
                        <IonItem button onClick={() => onPlantSelection(plant.ID)} key={plant.ID}>
                        <IonThumbnail slot="start">
                            <img decoding="sync" src={`assets/plantIcons/${plant.ID}.png`}/>
                        </IonThumbnail>
                        <IonLabel>{plant.name}</IonLabel>
                        <IonButton slot="end" color="transparent" onClick={(e) => addToFavourites(e, plant as PlantInfo)}>
                            <IonIcon slot="icon-only" icon={star} color={favPlants.includes(plant.ID as never) ? "warning" : "medium"} />
                        </IonButton>
                        </IonItem>
                    )})}
                    </IonList>

                </IonContent>
                <IonButton className={styles.fixedButton} expand="block" onClick={() => setShowModal(false)}>Close</IonButton>
            </div>
        )
    }, [searchText, selectedBed, plants]);

    const getPlantSelection = useCallback(() => {

        return (
            <div className={styles.plantSelection}>
                <IonHeader>
                    <IonSearchbar className={styles.searchbar} onIonChange={e => setSearchText(e.detail.value!)} placeholder="Enter plant name..."></IonSearchbar>
                </IonHeader>
                <IonContent>
                    <IonList className="plantList">
                    { plants.filter((plant) => searchText === '' || plant.name.toLowerCase().includes(searchText.toLowerCase())).map((plant) => {
                        return (
                        <IonItem button onClick={() => onPlantSelection(plant.ID)} key={plant.ID}>
                            <IonThumbnail slot="start">
                                <img decoding="sync" src={`assets/plantIcons/${plant.ID}.png`}/>
                            </IonThumbnail>
                        <IonLabel>{plant.name}</IonLabel>
                        <IonButton slot="end" color="transparent" onClick={(e) => addToFavourites(e, plant as PlantInfo)}>
                            <IonIcon slot="icon-only" icon={star} color={favPlants.includes(plant.ID as never) ? "warning" : "medium"} />
                        </IonButton>
                        </IonItem>
                    )})}
                    </IonList>
                </IonContent>
            </div>
        )
    }, [searchText, selectedBed, plants]);

    const renamePatch = useCallback(() => {
        presentNameChange({
            subHeader: 'Change garden patch name',
            inputs: [{
                name: 'patchName',
                type: 'text',
                value: patch.name,
                placeholder: 'Enter garden patch name...',
                attributes: {
                    autoComplete: "off",
                    onKeyDown: (e) => {
                        if (e.key === "Enter") {
                            e.preventDefault();
                            let patchName = (e.target as HTMLInputElement).value;
                            if (patchName === '' ) return false;
                            patch.name = patchName;
                            saveChanges(patch);
                            dismissNameChange();
                        }
                    }
                }
            }],
            buttons: [
                {
                    text: 'Confirm',
                    handler: (data) => {
                        if (data.patchName === '' ) return false;
                        patch.name = data.patchName;
                        saveChanges(patch);
                    }
                },
                {
                    text: 'Cancel',
                    role: 'cancel',
                },
            ]
        });
        setTimeout(()=> {
            const firstInput: any = document.querySelector('ion-alert input');
            firstInput.focus();}, 200);
    }, []);

    const onPlantSelection = useCallback((plant: any) => {
        selectedBed!.setPlant(plant);
        saveChanges(patch);
        setShowModal(false);
        setSearchText('');
    }, [selectedBed]);

    const onBedSelection = useCallback((bed, index) => {
        if (bed == selectedBed) {
            setSelectedBed(null);
        }
        else {
            if (bed!.plantID === undefined) bed!.clearPlant();
            setSelectedBed(bed);
        }
    }, [patch, selectedBed]);

    const getPlantBedButtonPanel = useCallback(() => {
        if(!selectedBed || (selectedBed?.plantID === -1 && window.innerWidth >= 1120 && window.innerHeight >= 720)) {
            return (
                <>
                    <IonButton color="secondary" className={styles.panelButton} onClick={() => waterAllPlants()}><IonIcon icon={water}/>&nbsp;Water all</IonButton>
                    <IonButton color="warning" className={styles.panelButton} disabled={!patch.beds.some((plant) => plant.isFertilizeAvailable(time))} onClick={() => fertilizeAllPlants()}><IonIcon icon={sparkles}/>&nbsp;Fertilize all</IonButton>
                </>
            )
        }

        switch (selectedBed!.plantState(time))
        {
            case PlantState.IsEmpty:
                return (
                        <IonButton className={styles.plantSomethingButton} onClick={() => addPlant()}>&nbsp;Plant something!</IonButton>
                )
            case PlantState.IsGrown:
                return (
                    <>
                    <IonButton color="success" className={styles.panelButton} onClick={() => removePlant()}><IonIcon icon={leaf}/> &nbsp;Harvest</IonButton>
                    <IonButton className={styles.panelButton} onClick={() => replantPlant()}><IonIcon icon={refresh}/> &nbsp;Replant</IonButton>
                    {/* <IonButton className={styles.moreOptions} onClick={
                        (e: any) => {
                        //e.persist();
                        setShowMoreOptionsPopover({ showPopover: true, event: e })
                        }}>
                        <IonIcon icon={ellipsisVertical} />
                    </IonButton> */}
                    {/* <IonButton className={styles.panelButton} onClick={() => {}}><IonIcon src='assets/icon/lock.svg'/> &nbsp;Lock</IonButton> */}
                    </>
                );
            case PlantState.IsDead:
                return (
                    <>
                        <IonButton color="danger" className={styles.panelButton} onClick={() => removePlant()}><IonIcon icon={sad}/>&nbsp;Remove</IonButton>
                    </>
                );
            case PlantState.IsGrowing:
            case PlantState.IsWilting:
                return (
                    <>
                        <IonButton color="secondary" disabled={selectedBed?.remainingWaterTime(time) === -1} className={styles.panelButton} onClick={() => waterPlant()}><IonIcon icon={water}/>&nbsp;Water</IonButton>
                        <IonButton color="warning" className={styles.panelButton} disabled={!selectedBed!.isFertilizeAvailable(time)} onClick={() => fertilizePlant()}><IonIcon icon={sparkles}/>&nbsp;Fertilize</IonButton>
                        <IonButton color="danger" className={styles.panelButton} onClick={() => removePlant()}><IonIcon icon={trash}/>&nbsp;Remove</IonButton>
                        <IonButton color="light" className={styles.moreOptions} onClick={
                            (e: any) => {
                            //e.persist();
                            setShowMoreOptionsPopover({ showPopover: true, event: e })
                            }}>
                            <IonIcon icon={ellipsisVertical} />
                        </IonButton>
                    </>
                )
        }
    }, [selectedBed, time]);

    const getPlantBedInfoPanel = useCallback(() => {
        if (selectedBed!.plantID === -1) return '';
        let state = selectedBed?.plantState(time);
        return (
            <div className={styles.plantInfoContainer}>
                <div className={styles.plantInfo1}>
                    <IonItem>
                        <IonLabel position="stacked">Plant name</IonLabel>
                        <p>{selectedBed!.getPlantInfo().name}</p>
                    </IonItem>
                    {(state !== PlantState.IsDead && state !== PlantState.IsEmpty) && <>
                        <IonItem>
                            <IonLabel position="stacked">Remaining time</IonLabel> <br/>
                            <IonProgressBar value={selectedBed!.getGrowProgress(time)} color="success"/>
                            <IonNote slot="end" color="success">{(selectedBed!.getGrowProgress(time)*100).toFixed(0)}%</IonNote>
                            {selectedBed!.remainingGrowTime(time) > 0 && <p>{getDurationString(selectedBed!.remainingGrowTime(time))} </p>}
                            {selectedBed!.remainingGrowTime(time) == 0 && <p>Ready to be harvested!</p>}
                        </IonItem>
                        {state !== PlantState.IsGrown && <IonItem>
                            <IonLabel position="stacked">Fertilizer progress</IonLabel>
                            <br/>
                            <IonProgressBar value={selectedBed!.getFertilizeProgress(time)} color="warning"/>
                            <IonNote slot="end" color="warning">{(selectedBed!.getFertilizeProgress(time)*100).toFixed(0)}%</IonNote>
                            { selectedBed!.remainingFertilizeTime(time) > 0 &&
                            <p>{getDurationString(selectedBed!.remainingFertilizeTime(time))} </p>}
                            {selectedBed!.remainingFertilizeTime(time) == 0 && <p>Ready to be fertilized!</p>}
                        </IonItem> }
                        {state !== PlantState.IsGrown && selectedBed?.remainingWaterTime(time) !== -1 && <IonItem>
                            <IonLabel position="stacked">Watering progress</IonLabel>
                            <br/>
                            <IonProgressBar value={selectedBed!.getWateringProgress(time)} color="secondary"/>
                            <IonNote slot="end" color="secondary">{(selectedBed!.getWateringProgress(time)*100).toFixed(0)}%</IonNote>
                            { selectedBed!.remainingWaterTime(time) > 0 &&
                            <p>{getDurationString(selectedBed!.remainingWaterTime(time))} </p>}
                            {selectedBed!.remainingWaterTime(time) == 0 && <p>Ready to be watered!</p>}
                        </IonItem> }
                        {state !== PlantState.IsGrown && selectedBed?.remainingWiltTime(time) !== -1 && selectedBed?.remainingWiltTime(time) !== 0 && <IonItem>
                            <IonLabel position="stacked">Wilting progress</IonLabel>
                            <br/>
                            <IonProgressBar value={selectedBed!.getWiltingProgress(time)} color="wilt"/>
                            <IonNote slot="end" color="wilt">{(selectedBed!.getWiltingProgress(time)*100).toFixed(0)}%</IonNote>
                            <p>{getDurationString(selectedBed!.remainingWiltTime(time))} </p>
                        </IonItem> }
                    </>
                    }
                </div>
                <div className={styles.plantInfo2}>
                    <IonItem>
                        <IonLabel position="stacked">Planted</IonLabel>
                        <p>{new Date(selectedBed!.plantTime as number).toLocaleString('pl-PL')} </p>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked">Last watered</IonLabel>
                        <p>{new Date(selectedBed!.waterTime as number).toLocaleString('pl-PL')} </p>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked">Last fertilized</IonLabel>
                        <p>{selectedBed!.fertilizeCount === 0 ? 'never' : new Date(selectedBed!.fertilizeTime as number).toLocaleString('pl-PL')} </p>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked">Fertilized</IonLabel>
                        <p>{`${selectedBed!.fertilizeCount} times`} </p>
                    </IonItem>
                    <IonItem>
                        <IonLabel position="stacked">Saved by fertilizing</IonLabel>
                        <p>{getDurationString(selectedBed?.growTimeReduction!)} </p>
                    </IonItem>
                    <IonItem>
                        <IonButton color="primary" className={styles.panelButton} onClick={() => setShowPlantInfoModal(true)}><IonIcon icon={search}/>&nbsp;See more info</IonButton>
                    </IonItem>
                </div>
            </div>
        )

    }, [selectedBed, time]);

    return (
        <div className={styles.pageBody}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{patch.name}</IonTitle>
                    <IonButtons slot="primary">
                    <IonButton fill="clear" onClick={() => renamePatch()}>
                        <IonIcon icon={create} />
                    </IonButton>
                    </IonButtons>
                </IonToolbar>
                <IonPopover
                    event={showMoreOptionsPopover.event}
                    isOpen={showMoreOptionsPopover.showPopover}
                    onDidDismiss={() => setShowMoreOptionsPopover({ showPopover: false, event: undefined })}
                    cssClass={styles.moreOptions}
                    >
                    <IonList>
                        {/* <IonItem button onClick={() => {} }>Lock</IonItem> */}
                        <IonItem button onClick={() => { replantPlant(); } }>Replant</IonItem>
                        <IonItem button onClick={() => { setIsGrown(); } }>Finish growing</IonItem>
                    </IonList>
                </IonPopover>
            </IonHeader>
            <IonContent fullscreen>
                <PlantInfoModal showModal={showPlantInfoModal} setShowModal={setShowPlantInfoModal} plant={selectedBed?.getPlantInfo()}/>
                <IonModal isOpen={showModal} cssClass={styles.PlantPageModal} onDidDismiss={(e) =>{ setShowModal(false); }}> { getPlantSelectionModal() }
                </IonModal>
                <div className={styles.pageWrapper}>
                    <div>
                        <div className={ patch.type === PatchType.Ooblong ? styles.RectangleWrapper : styles.SquareWrapper }>
                            <div className={styles.outerBorder}>
                                <div className={styles.innerBorder}>
                                    <div className={ getGridStyle() }>
                                        { patch.beds.map((bed: PlantBed, index) => {
                                            return (
                                            <button className={[styles.clickableBed, bed === selectedBed ? styles.selectedBed : ""].join(" ")} onClick={() => onBedSelection(bed, index) } key={index} >
                                                { <div className={styles.PatchBed}>{ getPlantIcon(bed) }</div> }
                                            </button>
                                            );
                                        })}
                                        { patch.type === PatchType.Deluxe && <div className={styles.PatchBed}/> }
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={styles.buttonPanel}> { getPlantBedButtonPanel() }  </div>
                    </div>
                    { selectedBed ? getPlantBedInfoPanel() : <div className={styles.placeholderText}>
                            Select a plant...
                        </div> }
                    { selectedBed && selectedBed.plantID === -1 && getPlantSelection()}
                </div>
        </IonContent>
    </div>
    );
}

export default GardenPatchPage;