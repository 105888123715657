import React, { useEffect, useState } from "react";
import { firestore, auth } from '../firebase';

const FavPlantsContext = React.createContext<any>({});

export function FavPlantsProvider({children}: {children: any}) {
    const user = auth.currentUser ? firestore.collection('users').doc(auth.currentUser?.uid) : null;
    const [favPlants, setFavPlants] = useState([]);

    useEffect(() => {
        if (user) {
            user.get().then((doc) => {
                if(doc && doc.exists) {
                if (!('favPlants' in doc.data()!)) {
                    doc.ref.set({ favPlants: [] }, {merge: true});
                }
                else setFavPlants(doc.data()!.favPlants);
                }
            });
        }
    }, [auth.currentUser]);

    return <FavPlantsContext.Provider value={{ favPlants, setFavPlants }}>{children}</FavPlantsContext.Provider>
}

export function useFavPlants() {
    const context = React.useContext(FavPlantsContext);
    return [context.favPlants, context.setFavPlants];
}