import React from "react";
import { FavPlantsProvider } from "../../hooks/useFavPlants";
import { TimeProvider } from "../../hooks/useTime";

export function ContextProviders({children}: {children: any}) {
    return (
        <TimeProvider>
            <FavPlantsProvider>
                {children}
            </FavPlantsProvider>
        </TimeProvider>
    )
}