import React, { useState, useCallback, useEffect, useMemo, MouseEvent } from 'react';

import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar, IonFab, IonFabButton, IonIcon, IonItem, IonList, IonFabList, IonButton, IonPopover, IonCardHeader, IonCardSubtitle, IonCard, IonCardTitle, IonCardContent, IonItemOptions, IonItemOption, IonItemSliding, IonLabel, IonNav, IonModal, useIonViewWillEnter, useIonLoading, useIonAlert, useIonToast } from '@ionic/react';
import { isPlatform, getPlatforms } from '@ionic/react';

import { add, water, sparkles, trash, key, close, ellipsisVertical } from 'ionicons/icons';

import GardenPatchPage from '../components/garden-patches/GardenPatchPage/GardenPatchPage';
import GardenPatchPreview from '../components/garden-patches/GardenPatchPreview/GardenPatchPreview';

import { Capacitor } from '@capacitor/core';
import { GardenPatch, PlantBed, PatchType, PatchConverter } from '../models/Patches';
import { plantData } from '../plants/PlantData';
import { auth, firestore } from '../firebase';

import { useCollectionData } from 'react-firebase-hooks/firestore'

import styles from './GardenPatchesTab.module.css'
import { useTime } from '../hooks/useTime';

const GardenPatchesTab: React.FC = () => {
  const [showSelectBedPopover, setShowSelectBedPopover] = useState({ showPopover: false, event: undefined });
  const time = useTime();

  const [showModal, setShowModal] = useState(false);
  const [selectedPatch, setSelectedPatch] = useState(null);
  const [presentLoading, dismissLoading] = useIonLoading();
  const [presentAlert, dismissAlert] = useIonAlert();
  const [presentToast, dismissToast] = useIonToast();

  const gardensRef = useMemo(() => firestore.collection('users').doc(auth.currentUser?.uid).collection('gardens').withConverter(PatchConverter), [auth.currentUser]);
  const [gardens] = useCollectionData(gardensRef.orderBy('createdAt'));

  useEffect(()=> {
    // console.log(gardens);
  }, [gardens])

  const saveChanges = useCallback((patch: GardenPatch) => {
    gardensRef.doc(patch.ID).withConverter(PatchConverter).set(patch, {merge: true});
  }, [gardensRef]);

  useEffect(() => {
    if (Capacitor.isNativePlatform()) {
      Capacitor.addListener!('App', 'backButton', e => {
        if (showModal) {
          setShowModal(false);
        }
      })
    }
  });

  const onPatchSelection = useCallback((patch) => {
    setSelectedPatch(patch);
    setShowModal(true);
  }, [gardensRef]);

  const addNewGardenPatch = useCallback((type: PatchType) => {
    let onAccept = (patchName: string) => {
      const doc = gardensRef.doc();
      let patch = new GardenPatch(doc.id, patchName, type);
      doc.withConverter(PatchConverter).set(patch);
    }
    presentAlert({
      subHeader: 'New garden!',
      keyboardClose: false,
      onWillDismiss: (e) => {
        setShowSelectBedPopover({ showPopover: false, event: undefined});
      },
      onDidPresent: (e) => {
        const firstInput: any = document.querySelector('ion-alert input');
        firstInput.focus();
      },
      inputs: [{
          name: 'patchName',
          type: 'text',
          value: '',
          placeholder: 'Enter garden patch name...',
          attributes: {
            autoComplete: "off",
            onKeyDown: (e) => {
              if (e.key === "Enter") {
                e.preventDefault();
                let patchName = (e.target as HTMLInputElement).value;
                if (patchName === '' ) return false;
                onAccept(patchName);
                dismissAlert();
              }
            }
          }
      }],
      buttons: [
        {
            text: 'Confirm',
            role: 'confirm',
            handler: (data) => {
                if (data.patchName === '' ) return false;
                onAccept(data.patchName);
            }
        },
        {
            text: 'Cancel',
            role: 'cancel',
        },
      ]
    });
  }, [gardensRef]);

  const removeGardenPatch = useCallback((patch: GardenPatch, index: number) => {
    (document.getElementById(`patchItem${index}`) as any).close();

    presentAlert({
      subHeader: 'Are you sure you want to remove this garden?',
      buttons: [
          {
              text: 'Confirm',
              handler: () => {
                  gardensRef.doc(patch.ID).delete();
              }
          },
          {
              text: 'Cancel',
              role: 'cancel',
          },
      ]
    });
  setShowSelectBedPopover({ showPopover: false, event: undefined});
  }, [gardensRef]);

  const fertilizeGardenPatch = useCallback((patch: GardenPatch, index: number) => {
    (document.getElementById(`patchItem${index}`) as any).close();
    patch.beds.map((bed) => bed.fertilizePlant(time));
    saveChanges(patch);
    presentToast({
      message: `Fertilized all plants I could!`,
      duration: 1000,
      position: "top",
      color: "primary"
    });
  }, [time]);

  const waterGardenPatch = useCallback((patch: GardenPatch, index: number) => {
    (document.getElementById(`patchItem${index}`) as any).close();
    patch.beds.map((bed) => bed.waterPlant(time));
    saveChanges(patch);
    presentToast({
        message: `Watered all plants I could!`,
        duration: 1000,
        position: "top",
        color: "tertiary"
    });
  }, [time]);

  const quickRemove = useCallback((e: MouseEvent, patch: GardenPatch) => {
    e.preventDefault();
    e.stopPropagation();

    presentAlert({
      subHeader: 'Are you sure you want to remove this garden?',
      buttons: [
          {
              text: 'Confirm',
              handler: () => {
                  gardensRef.doc(patch.ID).delete();
              }
          },
          {
              text: 'Cancel',
              role: 'cancel',
          },
      ]
    });
  setShowSelectBedPopover({ showPopover: false, event: undefined});
  }, [gardensRef]);

  const quickFertilize = useCallback((e: MouseEvent, patch: GardenPatch) => {
    e.preventDefault();
    e.stopPropagation();
    patch.beds.map((bed) => bed.fertilizePlant(time));
    saveChanges(patch);
    presentToast({
      message: `Fertilized all plants I could!`,
      duration: 1000,
      position: "top",
      color: "primary"
    });
  }, [time]);

  const quickWater = useCallback((e: MouseEvent, patch: GardenPatch) => {
    e.preventDefault();
    e.stopPropagation();
    patch.beds.map((bed) => bed.waterPlant(time));
    saveChanges(patch);
    presentToast({
        message: `Watered all plants I could!`,
        duration: 1000,
        position: "top",
        color: "tertiary"
    });
  }, [time]);

  const getPlantedInfo = useCallback((patch: GardenPatch) => {
    let plants : any;
    plants = {};
    patch.beds.map((bed) => {
      if(bed.plantID !== -1) {
        if(plants[bed.plantID] === undefined) plants[bed.plantID] = 1;
        else plants[bed.plantID] += 1;
      }
    });
    return Object.entries(plants).map(([key, value]) => { return `${value}x ${plantData.find((x) => x.ID.toString() === key)!["name"]}` });
  }, []);

  return (
    <IonPage className={styles.patchesTab}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>Garden Patches</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <IonModal isOpen={showModal} cssClass={styles.GardenPatchPageModal} onWillDismiss={() => setShowModal(false)}>
          { selectedPatch && <GardenPatchPage patch={selectedPatch!} dismiss={()=> setShowModal(false)} saveChanges={saveChanges} />}
          <IonButton onClick={() => setShowModal(false)}>Back</IonButton>
        </IonModal>
        <IonPopover
          event={showSelectBedPopover.event}
          isOpen={showSelectBedPopover.showPopover}
          onDidDismiss={() => setShowSelectBedPopover({ showPopover: false, event: undefined })}
        >
          <IonList>
            <IonItem button onClick={() => addNewGardenPatch(PatchType.Deluxe) }>Deluxe (8)</IonItem>
            <IonItem button onClick={() => addNewGardenPatch(PatchType.Ooblong) }>Ooblong (6)</IonItem>
            <IonItem button onClick={() => addNewGardenPatch(PatchType.Round) }>Round (4)</IonItem>
          </IonList>
        </IonPopover>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Garden Patches</IonTitle>
          </IonToolbar>
        </IonHeader>
        {gardens && <div className={styles.pageBody}>
          <div className={styles.patchList}>
            { gardens?.map((patch, index) => {
              return (
                  <IonItemSliding key={index} id={`patchItem${index}`} className={styles.patchSlideItem}>
                    <IonItem button onClick={() => onPatchSelection(patch)}>
                      <div className={styles.patchItem}>
                        <div className={styles.patchItemPreview}>
                          <GardenPatchPreview patch={patch as unknown as GardenPatch} />
                        </div>
                        <div className={styles.patchItemText}>
                          <IonLabel>{patch.name}</IonLabel>
                          <IonLabel className={styles.smallText}>{patch.type}</IonLabel>
                          { isPlatform('desktop') && <>
                            <IonButton color="warning" onClick={(e)=> quickFertilize(e, patch as unknown as GardenPatch)} disabled={!patch.beds.some((plant: PlantBed) => plant.isFertilizeAvailable(time))}><IonIcon icon={sparkles}/></IonButton>
                            <IonButton color="water" onClick={(e)=> quickWater(e, patch as unknown as GardenPatch)} disabled={!patch.beds.some((plant: PlantBed) => plant.plantID != -1)}><IonIcon icon={water}/></IonButton>
                          </> }
                          {/* <div className={styles.spacer}/>
                          { getPlantedInfo(patch).map((plant) => {
                            return (
                              <IonLabel className={styles.smallText}>{plant}</IonLabel>
                            )
                          }) } */}
                        </div>
                        { isPlatform('desktop') && <IonButton className={styles.patchDeleteButton} fill="clear" onClick={(e)=> quickRemove(e, patch as unknown as GardenPatch)} color="danger"><IonIcon icon={close}/></IonButton> }
                      </div>
                    </IonItem>
                    { isPlatform('mobile') && <IonItemOptions side="start">
                      <IonItemOption color="danger" onClick={() => removeGardenPatch(patch as unknown as GardenPatch, index)}>
                        <IonIcon icon={trash}/>
                      </IonItemOption>
                    </IonItemOptions>}
                    { isPlatform('mobile') && <IonItemOptions side="end">
                      <IonItemOption color="primary" onClick={() => fertilizeGardenPatch(patch as unknown as GardenPatch, index)} disabled={!patch.beds.some((plant: PlantBed) => plant.isFertilizeAvailable(time))}>
                        <IonIcon icon={sparkles}/>
                      </IonItemOption>
                      <IonItemOption color="secondary" onClick={() => waterGardenPatch(patch as unknown as GardenPatch, index)} disabled={!patch.beds.some((plant: PlantBed) => plant.plantID != -1)}>
                        <IonIcon icon={water}/>
                      </IonItemOption>
                    </IonItemOptions> }
                  </IonItemSliding>
              );
            })}
          </div>
          <div className={styles.addPatchButtonWrapper}>
            <IonButton className={styles.addPatchButton} onClick={
            (e: any) => {
              //e.persist();
              setShowSelectBedPopover({ showPopover: true, event: e })
            }}>
              <IonIcon icon={add} />
              Add new garden patch
            </IonButton>
          </div>
        </div> }

      </IonContent>
    </IonPage>
  );
};

export default GardenPatchesTab;

